<template>
  <div id="blog-post" v-if="post">
    <Navbar class="dark-menu-icon" />

    <div class="post">
      <h1 class="page-title">{{ post.title }}</h1>
      <div class="image">
        <HfaImage :name="post.id" path="assets/blogs" />
      </div>
      <h3>{{ post.subtitle }}</h3>
      <div class="content" v-html="post.content"></div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import HfaImage from "@/components/HfaImage.vue";

export default {
  name: "BlogPost",
  components: {
    Navbar,
    Footer,
    HfaImage
  },
  metaInfo: function() {
    return {
      title: ` - ${this.post.title}`,
      meta: [
        {
          name: "description",
          content: this.post.subtitle
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    post: function() {
      return this.$store.getters["blogPosts/postById"](this.$route.params.id);
    }
  }
};
</script>

<style lang="scss">
#blog-post {
  #navbar {
    margin-bottom: 3vh;
  }
  .post {
    h1 {
      margin-top: 120px;
      margin-bottom: 10px;
      font-size: 1.25em;
    }
    img {
      width: 100%;
    }
    .content {
      line-height: 2;
    }
    a {
      text-decoration: underline;
    }
    // margin-right: 5vw;
  }
  #footer {
    margin-top: 10vh;
  }

  @media (min-width: $desktop) {
    #navbar {
      margin-bottom: 10vh;
    }
    .post {
      width: 85%;
      margin: 0 auto;
      transform: translateX(-50px);

      h1 {
        margin-bottom: 50px;
      }
    }
    #footer {
      margin-top: 20vh;
    }
  }
}
</style>
